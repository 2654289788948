<template>
  <div class="v-step v-step--2">
    <div :class ="{'v-step--hidden_idf': current_status=== 'idf'}">
      <h2>{{ __('step_2.define_your_needs') }}</h2>

      <form @submit.prevent="submit()">
        <div class="slider-container">
          <section v-for="(index, value) in form.values" data-row>
            <div data-col="1/3">
              <h3 class="title-deco">{{ __('step_2.'+value+'_title')}}</h3>
            </div>
            <div data-col="2/3">
              <h4 v-html="__('step_2.'+value+'_subtitle')"></h4>
              <p>{{ __('step_2.'+value+'_description')}}</p>
              <vue-slider :min="1" :max="4" tooltip="none" :hide-label="true" :marks="true" v-model="form.values[value]"></vue-slider>
              <div class="vue-slider-legend"><span>Minimum</span><span>Maximum</span></div>
            </div>
          </section>
        </div>

        <div class="submit-container">
          <a class="link link--previous" @click="previous()"><span>{{ __('step_2.previous') }}</span></a>
          <div class="input-container" :class="{'loading':loading}">
            <input type="submit" class="button button--submit" :value="__('step_2.cta')"/>
          </div>
        </div>
      </form>
    </div>
    <div v-if="current_status=== 'idf'" class="container-idf">
      <div :class="{'loading':loading}"></div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'step2',
    methods: {
      submit(){

        if( this.loading )
          return;

        let self = this;
        this.loading = true;
        this.form.product = this.selectOffer(this.status, this.form.values.care, this.form.values.optic_dental, this.form.values.hospitalization, this.form.values.prevent);

        if( !this.form.product ){

          this.$store.commit('error', 'Aucun produit disponible');
          this.loading = false;
          return;
        }

        this.$store.commit('needs', this.form);
        this.$store.commit('offer', this.form.product.replace(/\D/g,''));
        this.$store.commit('proposal', this.form.product.replace(/\D/g,''));

        this.$store.dispatch('getQuote').then(function () {

          dataLayer.push({
            'event': 'funnel_step2',
            'customerType': self.current_status,
          });

          self.loading = false;
          self.$store.commit('step', 3);

        }).catch(function (e) {

          self.loading = false;
          self.$store.commit('error', e);

          console.debug(e);
        });
      },
      previous(){
        this.$store.commit('step', 1);
      }
    },
    computed:{
      status(){
        return this.$store.getters.status();
      }
    },
    data () {
      return {
        loading:false,
        current_status: null,
        form: {
          product: false,
          values:{
            care: 2,
            hospitalization: 2,
            optic_dental: 2,
            prevent: 2
          }
        }
      }
    },
    mounted() {

      let __ = this.__;

      let needs = this.$store.getters.needs();

      if( needs )
        this.form = needs;

      this.current_status = this.$store.getters.profil().main_user.status   

      if(this.$store.getters.profil().main_user.status === 'idf'){
        this.form.values = {
          care: 1,
          hospitalization: 1,
          optic_dental: 1,
          prevent: 1
        }
        this.form.product = "idf"

        this.submit()
      }

    }
  }
</script>

<style lang="scss">
  @import '../environment';
  .v-step{
    .container-idf{
      height: 100px; position: relative;
      .loading{
        background: white; color: white!important; cursor: wait;
        content: ""; width: 36px; height: 36px; border: 5px solid $c-secondary; border-left-color: transparent;
        position: absolute;
        border-radius: 50%; top: calc(50% - 18px); left: calc(50% - 18px); animation: rotation 1s linear infinite;
        pointer-events: none; transition: allow(opacity);
      }
    }

    text-align: left;
    .title-deco{
      font-size: 2rem; font-weight: 300; text-align: left;
      &:after{ content: ''; display: block; width: 4.6rem; height: 0.6rem; background: $c-primary; margin-top: 0.5rem }
    }

    &--hidden_idf{
        display: none;
      }
    h2+form{
      margin-top: 4rem;
      @media #{$to-small}{ margin-top: 0 }
    }
    h4{ font-weight: bold; font-size: 1.8rem; line-height: 1.33;
      & +p { margin-top: 1.5rem; font-weight: 300; color: $c-subtle; line-height: 1.4 }
    }
    .slider-container{
      @media #{$to-tablet}{
        [data-col] + [data-col]{ margin-top: 2rem }
      }
      @media #{$from-small} { margin-left: 4rem; margin-right: 4rem }
    }
    .vue-slider{
      height: 2px!important; margin-top: 1rem;
      @media #{$to-tablet}{ margin-left: 1rem; margin-right: 1rem  }
      .vue-slider-rail,.vue-slider-mark{ background: #C7C7C7 }
      .vue-slider-process,.vue-slider-dot,.vue-slider-mark-active{ background: $c-secondary }
      .vue-slider-dot{
        cursor: grabbing; border-radius: 50%;  position:relative; background: $c-secondary; width: 24px!important; height: 24px!important; margin-top:-2px;
        &:before{ content: ''; width: 20px; height: 20px; position: absolute; left: 2px;  top: 2px; z-index: -1;
          border: solid 3px white; background: $c-secondary; border-radius: 50% }
        &-handle{ width: 30px; height: 30px; position: absolute; top: 50%;  margin-top: -15px; left: 50%; margin-left: -15px }
      }
      .vue-slider-mark{ border-radius: 50%; height: 8px!important; width: 8px!important; cursor: pointer }
    }
    .vue-slider-legend{ display: flex; justify-content: space-between; color: $c-subtle; font-weight: 300; margin-top: 0.5rem  }
    .subscription-before{
      font-weight: 300; margin-top: 1rem;
      b{ font-weight: 300; color: $c-primary }
    }
    .link--previous{ margin-right: auto }
  }
</style>
