<template>
  <div class="v-step v-step--4">

    <h2>{{ __('step_4.your_choice') }}</h2>

    <div :class ="{final_step_idf: status=== 'idf'}" class="final-step"  >
      <div class="selected-heading">
        <div :style="{'background-color': offer.couleur}" class="image">
          <img :src="'img/offers/'+selected_year+'/'+offer.logo" class="logo"  :class ="{logo_idf: status=== 'idf'}">
          <h3 class="selected-title offer-title" 
          :class ="{idf_title: status=== 'idf'}"
          :style="{'color': offer.couleur_3 }">
            <span class="light">{{ __('step_3.formula') }}</span>
            <b :class ="{format_idf: status=== 'idf'}"  v-html="format(offer.titre)"></b>
          </h3>
        </div>
        <div v-if="status!= 'idf'">
          <p>{{ offer.texte_step4 }}</p>
        </div>
      </div>

      <div class="smart-plan final-step-box" v-if="status==='lmf'">
        <h4>{{ __('step_4.smart_plan') }}</h4>

        <div class="option">
          <div class="input-checkbox">
            <input type="checkbox" v-model="form.pharmacy" />
            <span class="checkmark" data-icon="tick"></span>
          </div>

          <label>
            <b>{{ __('step_4.pharmacy') }}</b>
            <span class="light">{{ __('step_4.details_pharmacy') }}</span>
          </label>
          <div class="price">
            {{ roundPrice(prices.options.ph) }}<span>{{ __('sidebar.monthly') }}</span>
          </div>
        </div>
      </div>

      <div class="smart-plan final-step-box" v-if="status==='terrea' && false">
        <h4>{{ __('step_4.warranties') }}</h4>

        <div class="option">
          <div class="input-checkbox">
            <input type="checkbox" v-model="form.salary_loss" />
            <span class="checkmark" data-icon="tick"></span>
          </div>

          <label>
            <b>{{ __('step_4.salary_loss') }}</b>
            <span class="light">{{ __('step_4.details_salary_loss') }}</span>
          </label>
        </div>

        <div class="option">
          <div class="input-checkbox">
            <input type="checkbox" v-model="form.disability" />
            <span class="checkmark" data-icon="tick"></span>
          </div>

          <label>
            <b>{{ __('step_4.disability') }}</b>
            <span class="light">{{ __('step_4.details_disability') }}</span>
          </label>
        </div>

        <div class="option">
          <div class="input-checkbox">
            <input type="checkbox" v-model="form.death" />
            <span class="checkmark" data-icon="tick"></span>
          </div>

          <label>
            <b>{{ __('step_4.death') }}</b>
            <span class="light">{{ __('step_4.details_death') }}</span>
          </label>
        </div>
        <b v-html="__('step_4.callback')"></b>
      </div>

      <!--
      <div class="final-step-box" v-if="status==='terrea'">
	      <div class="final-step-option funeral" v-if="!isSenior()">
		      <div class="input-checkbox">
			      <input type="checkbox" v-model="form.funeral" />
			      <span class="checkmark" data-icon="tick"></span>
		      </div>
		      <div class="final-step-content">
			      {{ __('step_4.funeral_allowance') }}
			      <small>{{ __('step_4.details_funeral_allowance') }}</small>
			      <p v-if="form.funeral" class="funeral-message">{{ __('step_4.funeral_message') }}</p>
		      </div>
	      </div>
      </div>
      -->

      <div class="final-step-box" v-if="status==='lmf'">
        <h4>{{ __('step_4.optional') }}</h4>
        <div class="final-step-option">
          <div class="input-checkbox">
            <input type="checkbox" v-model="form.overrun" />
            <span class="checkmark" data-icon="tick"></span>
          </div>
          <div class="final-step-content">
            {{ __('step_4.overrun_fees') }}
            <small>{{ __('step_4.details_overrun_fees') }}</small>
          </div>
          <div class="price">
            {{ roundPrice(prices.options.dh) }}
            <small>{{ __('sidebar.monthly') }}</small>
          </div>
        </div>
        <div class="final-step-option">
          <div class="input-checkbox">
            <input type="checkbox" v-model="form.hospit" />
            <span class="checkmark" data-icon="tick"></span>
          </div>
          <div class="final-step-content">
            {{ __('step_4.hospit_reinforcement') }}
            <small v-html="__('step_4.details_hospit')"></small>
          </div>
          <div class="price">
            {{ roundPrice(prices.options.ch) }}
            <small>{{ __('sidebar.monthly') }}</small>
          </div>
        </div>
        <!--
        <div class="final-step-option funeral" v-if="!isSenior()">
          <div class="input-checkbox">
            <input type="checkbox" v-model="form.funeral" />
            <span class="checkmark" data-icon="tick"></span>
          </div>
          <div class="final-step-content">
            {{ __('step_4.funeral_allowance') }}
            <small>{{ __('step_4.details_funeral_allowance') }}</small>
            <p v-if="form.funeral" class="funeral-message">{{ __('step_4.funeral_message') }}</p>
          </div>
        </div>
        -->
      </div>

      <small v-if="$store.getters.profil().main_user.status != 'idf'" class="rgpd" v-html="__('step_4.rgpd')"></small>

      <div class="sub-element-container"  style="justify-content: space-between;" v-if="$store.getters.profil().main_user.status === 'idf'">
              <div class="contenu_1">
                <p class="content_message" >Vous avez besoin <br>d’en discuter ?</p>
                <p class="message_idf" >Appelez nous au <br>
                <span class="btn-link">01 30 99 25 56 </span><br>
               </p>  
                <p  class="message_idf"> de 9H à 18h  <br>
                du Lundi au Vendredi </p>
              </div>


               <div class="contenu_2">
                <p class="content_message">Vous préférez <br>prendre un peu de <br> temps ?</p>
                <p class="message_idf">Cliquez sur le bouton <br>
                <span class="btn-link">Recevoir le devis par mail </span>
                </p>
              </div>
              
            
              <div class="contenu_3">
                <p class="content_message">Vous êtes prêt à <br> souscrire ?</p>
                <p class="message_idf">Cliquez sur le lien <br>en bas de page <br>
                <span class="btn-link">Souscrire en ligne </span>
                </p>
              </div>
              
      </div>


      <div class="input-container input-container--mail" :class="{'loading':loading.mail}" v-if="status !='idf'">
        <a class="button button--light" @click="mail()">{{ __('step_4.cta') }}</a>
      </div>
    </div>

    <div class="submit-container submit-container--padding">
      <a class="link link--previous" @click="previous()"><span>{{ __('step_2.previous') }}</span></a>

      <div v-if="status==='idf'">
        <div class="input-container" :class="{'loading':loading.mail}">
          <button @click="mail()" class="button button--submit">{{ __('step_4.cta') }}</button>
        </div>
      </div>

      <a class="link link--reset" @click="reset()">{{ __('sidebar.another_quote') }}</a>
      <div class="input-container input-container--subscribe" :class="{'loading':loading.subscribe}">
        <a class="button" @click="subscribe()">{{ __('sidebar.cta') }}</a>
      </div>
    </div>

    <transition name="fade">
      <div class="c-popin c-popin--email" v-if="showMailPopin" @click.self="closePopin">
        <div class="c-popin-content">
          <div data-icon="mail-sent" class="icon"></div>
          <h2 class="c-popin-title" >{{ profil.main_user.firstname }} {{ profil.main_user.lastname }},<br/>{{__('popin_mail_sent.mail_sent') }}</h2>
          <p class="email">{{ profil.main_user.email }}</p>
          <p v-html="__('popin_mail_sent.the_lmf_is')" class="lmf"></p>
          <div class="button-container">
            <button class="button" @click="closePopin">{{ __('transverse.continue') }}</button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

  export default {
    name: 'step4',
    data(){
      return {
        loading:{
          mail:false,
          subscribe:false
        },
        form:{
          pharmacy:true,
          overrun:false,
          hospit:false,
          funeral:false,
          death:false,
          disability:false,
          salary_loss:false
        },
        showMailPopin: false
      }
    },
    methods:{
      previous(){
        this.$store.commit('step', 3);
      },
      reset(){
        this.$store.commit('reset');
      },
      subscribe(){

        let self = this;

        if( this.loading.subscribe )
          return;

        this.loading.subscribe = true;

        this.$store.dispatch('updateUser').then(function(){

          self.$store.dispatch('subscribe').then(function (data) {

            dataLayer.push({
              'event': 'funnel_step4',
              'customerType': self.$store.getters.profil().main_user.status,
            });

            self.loading.subscribe = false;

            if( self.isInIframe() )
              window.parent.postMessage({type:'url', url:data.url}, "*");
            else
              window.open(data.url);

          }).catch(function (e) {

            self.$store.commit('error', e);
            self.loading.subscribe = false;
          });
        }).catch(function (e) {

          self.$store.commit('error', e);
          self.loading.subscribe = false;
        });
      },
      isSenior(){
        let ageDifMs = Date.now() - this.profil.main_user.birthday;
        let ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970)>65;
      },
      mail(){

        if( this.loading.mail )
          return;

        let self = this;
        this.loading.mail = true;

        this.$store.dispatch('updateUser').then(function(){

          self.$store.dispatch('sendMail').then(function(){

            self.showMailPopin = true;
            self.loading.mail = false;
            self.showPopin();

          }).catch(function (e) {

            self.$store.commit('error', e);
            self.loading.mail = false;
          });

        }).catch(function (e) {

          self.$store.commit('error', e);
          self.loading.mail = false;
        });
      },
      format(string){
        return string.replace(' 1', '&nbsp;1').replace(' 2', '&nbsp;2').replace(' 3', '&nbsp;3').replace(' 4', '&nbsp;4')
      },
      ntobr(string){
        return string.replace(/\n/g, '<br/>');
      },
      commaToBr(string){
        return string.replace(',', '<br/>');
      },
      showPopin() {

        let self = this;
        setTimeout(function () {
          var target = document.querySelector(".v-step");
          var popin_content = document.querySelector(".c-popin-content");
          self.scrollToElement(".v-step");
        }, 100)

      },
      closePopin(){
        this.showMailPopin = false;
      },
    },
    watch:{
      form:{
        handler: function (val, oldVal) {
          this.$store.commit('options', this.form);
        },
        deep: true
      }
    },
    computed: {
      needs(){
        return this.$store.getters.needs();
      },
	    selected_year(){
		    return this.$store.getters.selected_year();
	    },
      offer(){
        return this.getOffer(this.$store.getters.status(),this.selected, this.$store.getters.selected_year());
      },
      selected(){
        return this.$store.getters.offer();
      },
      prices(){
        return this.$store.getters.prices();
      },
      status(){
        return this.$store.getters.status();
      },
      profil(){
        return this.$store.getters.profil();
      }
    },
    mounted() {

      if( this.status === 'terrea' || this.status === 'idf')
        this.form.pharmacy = false;

      this.$store.commit('options', this.form);
    }
  }
</script>

<style lang="scss">
  @import '../environment';
  .v-step--4{
    .submit-container--padding{
      @media #{$to-tablet}{ margin-bottom: 8rem }
    }
    .link--reset, .input-container--subscribe{
      @media #{$from-small}{ display: none }
    }
  }



  .contenu_3 > .btn-link, .contenu_2 > .btn-link , .contenu_1 > .btn-link , .btn-link  {
     font-weight: bold;
     font-size: 1.3rem;
     color: #3e0b7a;
     text-align: center;
    }

    .message_idf {
      text-align: center;
      line-height: 22px;
      font-size: 1.5rem;
      *{ font-size: 1.5rem; }
    }

    .final_step_idf {
      border: 0px !important
    }

    .v-step .offer-title .format_idf {
      font-size: 19px;
      line-height: 1;
    }

    .contenu_1 >  span, .contenu_2 >  span, .contenu_3 >  span {
      text-align: center;
      margin: 15px;
    }
    

    .contenu_3 > .content_message, .contenu_2 > .content_message , .contenu_1 > .content_message  {
     font-weight: bold;
     font-size: 1.7rem;
     color: #4E9870;
     text-align: center;
     line-height: 22px;
    }
    .contenu_3 ,.contenu_2,  .contenu_1 {
      padding: 15px;
    }

    .image {
      display: flex;
      flex-direction: column-reverse;
    }


  .v-step{
    text-align: left;
    .final-step{
      border: 1px solid #E6E6E6; box-shadow: 2px 1px 2px rgba(0,0,0,0.04); margin: 2.5rem; position: relative;
      padding-bottom: 5rem;
      .rgpd{ padding: 2rem 4rem }
      h3{ margin-top: 0 }
      @media #{$to-small}{ margin: 3rem 0; margin-bottom: 6rem; border: 0; box-shadow: none }
      .selected-heading{
        display: flex;
        justify-content: center;
        & > *{ display: flex; align-items: center; justify-content: center }
        & > div:first-of-type{
          @media #{$from-small}{ width: 43%; margin-left: -1px; margin-top: -1px; width: 267px;
    height: 267px; }
          & + *{ flex: 1; background: #f9f9f9; font-size: 1.8rem; font-weight: bold; padding: 3rem
           }
        }
        @media #{$to-tablet}{
          flex-direction: column;
          & > *{ width: 100% }
        }
      }
      .input-container{ position: absolute; left: 50%; bottom: -2.5rem; transform: translateX(-50%); white-space: nowrap;     backface-visibility: hidden; }
    }
    .logo{ margin: 2.5rem 1rem; width: 30% }

    .logo_idf { margin: 2.5rem 1rem; width: 74% }

    .selected-title{ color: white; padding: 2.5rem 2.5rem 2.5rem 0; text-align: left }
    .idf_title{color: white; padding: 2.5rem 0; text-align: center}

    .input-checkbox{
      & + *{ margin-left: 2.5rem }
      input[type="checkbox"]{ box-shadow: none; border-radius: $border-radius; width: 3rem; height: 3rem;
        &:checked ~ .checkmark{ opacity: 1 }
      }
      .checkmark{
        opacity: 0; transition: allow(opacity);
        &:before{ font-size: 2rem }
      }

    }

    .final-step-box{
      margin-left: 2rem; margin-right: 2rem;
      @media #{$to-tablet}{ margin-left: 1rem; margin-right: 1rem }
      h4{ font-weight: 900; margin-left: 15px }
    }
    .smart-plan{
      border: solid 2px $c-primary; margin: 4rem 0.5rem; position: relative; padding: 2.5rem 1.5rem;
      .option{ display: flex; align-items: center }
      .option+.option{ margin-top: 2rem }
      .option+b{ margin-top: 2rem; display: block; font-size: 1.6rem }
      @media #{$to-tablet}{
        flex-wrap: wrap; margin-left: 1.5rem; margin-right: 1.5rem;
      }
      @media #{$from-small}{
        margin: 6rem 2rem 5rem;  padding: 3rem 4rem 3rem 2rem; margin-bottom: 1rem;
      }
      h4{ display: inline-block; position: absolute; background: white; top: -1.5rem; padding: 0 5px;  line-height: 1; left: 0 }
      label{
        b{ font-size: 1.6rem }
        b,span{ display: block }
        @media #{$to-tablet}{ flex: 1 }
      }
      .price{
        margin-left: auto; font-weight: bold; font-size: 1.8rem; letter-spacing: 0.4px; text-align: center;
        span{ display: block; font-size: 1.4rem }
        @media #{$to-tablet}{ width: 100%; text-align: right; margin-top: 1rem }
      }
    }
    .final-step-option{
      margin-left: 2rem; font-weight: 300; display: flex; align-items: flex-start; padding: 1.5rem; font-size: 1.6rem;
      flex-wrap: wrap; position: relative;
      .input-checkbox{
        margin-top: 3px;
        input[type="checkbox"]{ width: 1.3rem; height: 1.3rem; border-radius: 2px; border-width: 1px  }
        .checkmark:before{ font-size: 0.9rem }
      }
      &:first-of-type{ margin-top: 1.5rem }
      & + .final-step-option{ border-top: solid 1px $c-background }
      .price{
        margin-left: 3rem; flex-shrink: 0; text-align: center; font-size: 1.6rem;
        @media #{$to-tablet}{ width: 100%; text-align: right; margin-top: 1rem; margin-left: auto }
      }
      small{ display: block }
      .final-step-content{ flex: 1 }

      &.funeral{
        background: $c-background; margin-top: 2rem;
        .funeral-message{ margin-top: 1.5rem; color: $c-primary; width: 100%; font-size: 1.4rem }
      }
    }
    &--4{
      @media #{$from-small}{  .subscribe{ display: none } }
    }
    .c-popin--email{
      .icon{ font-size: 10rem; line-height: 1; margin-bottom: 0 }
      .email{
        font-size: 2.2rem; font-weight: 300; text-align: center;
        @media #{$to-tablet}{ font-size: 1.6rem }
      }
      .lmf{ font-size: 1.6rem; font-weight: 300; line-height: 1.4; margin-top: 3rem;
        @media #{$to-tablet}{ font-size: 1.4rem }
      }
    }
  }
</style>
