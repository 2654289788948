<template>
  <aside class="c-cart" v-if="showSidebar || current_step === 4">
    <div class="c-cart-footer">
      <a @click="openPopin" data-icon="next" v-if="!popinCart" class="action action--open">{{ __('sidebar.show_details') }}</a>
      <a @click="closePopin" data-icon="close" v-else class="action action--close">{{ __('sidebar.close_details') }}</a>
      <div class="title" v-html="format(offer.titre)"></div>
      <div class="price"><small>{{ __('sidebar.final_price') }}</small>
        <b>{{ finalPrice() }}</b></div>
    </div>
    <transition name="slide-left">
      <div v-if="showSidebar || popinCart" class="c-cart-content">
        <h3>{{ __('sidebar.your_quote') }}<a @click="closePopin" data-icon="close" class="popin-cart-close"></a></h3>

        <div v-if="current_step === 1" class="content content--step1">{{ __('sidebar.your_informations_will_appear') }}</div>
        <div v-if="current_step>1" class="content content--other">
          <h4>{{ __('sidebar.your_family') }}</h4>
          <strong>{{ __('sidebar.you') }}</strong>
          <p>{{ profil.main_user.firstname }} {{ profil.main_user.lastname }}<br>{{ profil.main_user.status }}</p>
          <strong v-if="profil.additional_users.length > 0">{{ __('sidebar.your_beneficiaries') }}</strong>
          <p v-for="user in profil.additional_users">{{ user.firstname }} {{ user.lastname }}</p>
          <p class="start_date">{{ __('sidebar.start_date') }}<br/>
            <b>{{ formatDate(profil.start_date) }}</b></p>
        </div>
        <div v-if="current_step>2" class="content content--other">
          <h4>{{ __('sidebar.needs') }}</h4>
          <div class="needs" v-for="(value, key) in needs.values">
            <span :data-icon="key" class="needs-icon"></span>
            <div class="range-container">
              {{ __('sidebar.'+key) }}
              <div class="range">
                <span :style="{ width: (value/4)*100 + '%'}"></span>
              </div>
            </div>

          </div>
        </div>
        <div v-if="current_step>3" class="border-top">
          <div class="content content--other">
            <h4>{{ __('sidebar.health_coverage') }}</h4>
            <div class="c-cart-row">
              <div><b>{{ offer.titre }}</b></div>
              <div><b>{{ roundPrice(prices.plan) }}<small>{{ __('sidebar.monthly') }}</small></b></div>
            </div>
          </div>
          <div class="content content--other" v-if="options.pharmacy||options.overrun||options.hospit||options.salary_loss||options.disability||options.death">
            <h4>{{ __('sidebar.your_options') }}</h4>
            <div class="c-cart-row" v-if="options.pharmacy">
              <div>{{ __('sidebar.pharmacy') }}</div>
              <div>{{ roundPrice(prices.options.ph) }}<small>{{ __('sidebar.monthly') }}</small></div>
            </div>
            <div class="c-cart-row" v-if="options.overrun">
              <div>{{ __('sidebar.overrun') }}</div>
              <div>{{ roundPrice(prices.options.dh) }}<small>{{ __('sidebar.monthly') }}</small></div>
            </div>
            <div class="c-cart-row" v-if="options.hospit">
              <div>{{ __('sidebar.hospit') }}</div>
              <div>{{ roundPrice(prices.options.ch) }}<small>{{ __('sidebar.monthly') }}</small></div>
            </div>
            <div class="c-cart-row" v-if="options.salary_loss">
              <div>{{ __('sidebar.salary_loss') }}</div>
            </div>
            <div class="c-cart-row" v-if="options.disability">
              <div>{{ __('sidebar.disability') }}</div>
            </div>
            <div class="c-cart-row" v-if="options.death">
              <div>{{ __('sidebar.death') }}</div>
            </div>
          </div>
          <div class="content content--other">
            <div class="c-cart-row c-cart-row--background">
              <div><b>{{ __('sidebar.final_price') }}</b></div>
              <div><b>{{ finalPrice() }}<small>{{ __('sidebar.monthly') }}</small></b></div>
            </div>
          </div>
          <div class="submit-container">

            <div class="input-container" :class="{'loading':loading}" v-if="!subscribed">
              <a class="button" @click="subscribe()">{{ __('sidebar.cta') }}</a>
            </div>

            <div class="message-container" v-else>
              {{ __('sidebar.message') }}
            </div>

          </div>
        </div>
        <a class="link link--reset" @click="reset()" v-if="current_step>1">{{ __('sidebar.another_quote') }}</a>
      </div>
    </transition>
  </aside>
</template>

<script>
export default {
  name: 'cart',
  data(){
    return {
      showSidebar: false,
      popinCart: false,
      loading :false,
      subscribed :false
    }
  },
  methods:{
    formatDate(timestamp){
      let date = new Date(timestamp);
      return String(date.getDate()).padStart(2,'0')+'/'+String(date.getMonth()+1).padStart(2,'0')+'/'+date.getFullYear();
    },
    subscribe(){

      let self = this;

      if( this.loading )
        return;

      this.loading = true;

      this.$store.dispatch('updateUser').then(function(){

          self.$store.dispatch('subscribe').then(function (data) {

            self.loading = false;

            if( self.isInIframe() )
              window.parent.postMessage({type:'url', url:data.url}, "*");
            else
              location.href = data.url;

          }).catch(function (e) {

            self.$store.commit('error', e);
            self.loading = false;
          });

      }).catch(function (e) {

        self.$store.commit('error', e);
        self.loading = false;

      });
    },
    finalPrice(){
      let price = this.prices.plan;

      if( this.options.pharmacy )
        price += this.prices.options.ph;

      if( this.options.overrun )
        price += this.prices.options.dh;

      if( this.options.hospit )
        price += this.prices.options.ch;

      return this.roundPrice(price);
    },
    reset(){
      this.$store.commit('reset');
    },
    openPopin(){
      this.popinCart = true;
      document.body.classList.add('popin-cart-open')

    },
    closePopin(){
      this.popinCart = false;
      document.body.classList.remove('popin-cart-open')

    },
    format(string){

      if( string )
        return string.replace(' 1', '&nbsp;1').replace(' 2', '&nbsp;2').replace(' 3', '&nbsp;3').replace(' 4', '&nbsp;4').replace('LMF','<small>LMF</small>')

      return ''
    }
  },
  computed: {
    current_step(){
      return this.$store.getters.step();
    },
    profil(){
      return this.$store.getters.profil();
    },
    needs(){
      return this.$store.getters.needs();
    },
    offer(){
      return this.getOffer(this.$store.getters.status(), this.selected, this.$store.getters.selected_year());
    },
    selected(){
      return this.$store.getters.offer();
    },
    prices(){
      return this.$store.getters.prices();
    },
    options(){
      return this.$store.getters.options();
    }
  },
  mounted(){
    if(window.innerWidth > 1023){
      this.showSidebar = true;
    }
    let vm = this;
    window.addEventListener('resize',function(){
      if(window.innerWidth > 1023){
        vm.showSidebar = true;
        vm.popinCart = false;
        document.body.classList.remove('popin-cart-open')
      } else {
        vm.showSidebar = false;
      }
    });
  }
}
</script>


<style lang="scss">
@import '../environment';
.c-cart{
  background: white; width: 100%; font-weight: 300;
  @media #{$to-tablet}{ position: static; border: 0 }
  @media #{$from-small}{ margin-top: 3.6rem; }
  @media  #{$to-small}{margin-top: 11rem}
  .link--reset{background: $c-background; text-align: center; display: block; padding-top: 2rem }
  h3{
    display: block; color: white; text-align: center; font-weight: 600; font-size: 2.3rem; background: $c-tertiary; padding: 1.8rem;
    position: relative; font-family: AvenirNextCondensed; text-transform: uppercase;
  }
  &-offer{ text-transform: uppercase }
  .content{
    padding: 2rem 1rem; text-align: center;
    &--step1{ font-style: italic }
    &--other{
      text-align: left; padding-left: 0; padding-right: 0; margin-left: 2rem; margin-right: 2rem; position: relative;
      & + .content--other{ border-top: solid 1px $c-background }
      strong{ display: block; margin-top: 1.5rem }
      & > * + *{ margin-top: 1rem}
      .start_date{ margin-top: 3rem }
    }
  }
  .border-top{
    .content:first-of-type{ border-top: solid 1px $c-background }
  }
  h4{color: $c-tertiary; font-weight: bold; font-size: 1.6rem }
  .needs{
    display: flex; align-items: center;
    .needs-icon{ color: $c-tertiary; padding-right: 2rem; border-right: solid 1px $c-background; font-size: 3rem }
    .range-container{ flex: 1; margin-left: 2rem; margin-right: 15%; }
    .range{
      position: relative; height: 5px; border-radius: $border-radius; background: rgba(#B5B6B6,0.5);

      span{ position: absolute; height: 100%; left: 0; border-radius: $border-radius; background: $c-tertiary }
    }
    & + p{ margin-top: 3rem;}
  }
  &-row{
    display: flex; justify-content: space-between;
    small{ display:block }
    & > div:nth-of-type(2){ text-align: right }
    &--background{ background: $c-background; padding: 1rem }
  }
  .submit-container{
    text-align: center; background: $c-background; margin-top: 3rem;
    @media #{$to-tablet}{
      padding: 3rem;
    }
    .input-container{
      margin-top: -3rem;
      @media #{$to-tablet}{
        margin-top: 0;
      }
    }
  }

  .message-container{
    background:$c-tertiary; padding: 2rem; color: #fff; margin-top: -3rem; font-size: 1.6rem;
  }

  &-footer{
    @media #{$from-small}{ display: none };
    position:fixed; bottom: 0; left: 0; width: 100%; height: 7rem; background:$c-tertiary; color: white; display: flex;
    align-items: center; z-index: 11;
    .action{
      background: #2378ad; align-self: stretch; align-items: center; display: flex; padding: 1rem; width: 25%; font-size: 1.2rem;
      &:before{ font-size: 1.5rem; margin-right: 0.5rem; margin-bottom: 1.5rem }
      &--close:before{ font-size: 1rem }
    }
    .title{ font-weight: bold; font-size: 1.8rem; margin-left: 1.5rem;
      small{ display: block; font-size: 1.4rem }
    }
    .price{
      margin-left: auto; margin-right: 1.5rem; font-size: 1.8rem; text-align: right;
      small{ display: block; font-size: 1.2rem }
    }
  }

  .popin-cart-close{
    position: absolute; top: 0; bottom: 0; right: 0; padding: 1rem; background: $c-secondary;
    display: flex; align-items: center; font-size: 1.6rem;
    @media #{$from-small}{ display: none }
  }

  @media #{$to-tablet}{
    .popin-cart-open &{
      &:before{ content: ''; position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0,0,0,0.2); z-index: 1 }
    }

    &-content{
      position: fixed; top: 0; bottom: 7rem; background: white; margin: 1rem; left: 0;  z-index: 10;  overflow: auto; right: 0;
    }
  }
}
</style>
