<template>
	<transition>
		<div class="c-popin c-popin--error" @click.self="close" v-if="error || softError">
			<div class="c-popin-content">
				<div class="icon" data-icon="worker"></div>
        <h2 v-if="error" class="c-popin-title">{{ (error !== true && debug) ? error : __('popin_error.technical_issue') }}</h2>
        <h2 v-else-if="softError" class="c-popin-title">{{ softError }}</h2>
				<div class="button-container">
					<button class="button" @click="close">{{ __('transverse.continue') }}</button>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
	export default {
		name: "PopinError",
		data(){
			return{
				debug: process.env.VUE_APP_DEBUG
			}
		},
		methods:{
			close(){
        this.$store.commit('error', false);
        this.$store.commit('softError', false);
			}
		},
		computed:{
			error(){
				return this.$store.getters.error();
			},
      softError(){
        return this.$store.getters.softError();
      }
		}
	}
</script>

<style lang="scss">
	@import '../environment';
	.c-popin{
		position: fixed; left: 0; right: 0; bottom: 0; top: 0; display: flex; align-items: flex-start; justify-content: center; z-index: 15;
		background: rgba(black, 0.5);
		&-content{
			width: 35%; background: white; padding: 5rem 7.5rem 5rem; position: absolute; top: 10%; border-radius: 8px ;
			@media #{$to-tablet}{
				width: 90%;
			}
		}
		.c-popin-title{
			font-size: 2.4rem; font-weight: 900; color: $c-primary; text-align: center;
			@media #{$to-tablet}{ font-size: 2rem }
		}
		.button-container{ text-align: center; margin-top: 3rem }
		.icon { text-align: center; font-size: 6rem; color: $c-primary; margin-bottom: 2rem }
	}
</style>