<template>
  <div class="v-step v-step--3" :class="'v-step-'+this.status">

    <h2>{{ __('step_3.our_proposal') }}</h2>

    <div v-if="prices && offer">

      <div class="selected-offer">
        <div class="offer-summary" :style="{'background-color': offer.couleur }">
          <h3 class="offer-title" :style="{'color': offer.couleur_3 }">
            <span>{{ __('step_3.formula') }}</span>
            <b v-html="format(offer.titre)"></b>
          </h3>

          <div class="logo">
            <img :src="'img/offers/'+selected_year+'/'+offer.logo">
            <img :src="'img/offers/'+selected_year+'/'+offer.logo_rac" alt="RAC0" class="rac0" v-if="offer.logo_rac"/>
          </div>

          <div class="price" :style="{'background-color': offer.couleur_2 }">
            <b>{{ roundPrice(prices.plan, false) }}<small>€</small></b>
            <span>{{ __('step_3.monthly') }}</span>
          </div>

          <div class="pdf" v-if="$store.getters.profil().main_user.status === 'idf' && offer.brochure" :style="{'color': offer.couleur_3,'background-color': offer.couleur_5 }" >
            <a :href="api_endpoint+'/pdf/'+selected_year+'/'+offer.pdf" target="_blank">{{ __('step_3.details') }}</a>
            <a @click="popinGarantieIdf()">{{ __("step_3.detail_guaranty") }}</a>
          </div>
          <a v-else class="pdf" :style="{'color': offer.couleur_3 }" :href="api_endpoint+'/pdf/'+selected_year+'/'+offer.pdf" target="_blank">{{ __('step_3.details') }}</a>

        </div>

        <div class="offer-guaranties">
          <div class="guaranties-intro">
            <div class="guaranties-intro-image" v-if="status==='lmf'">
              <img :src="'img/logo-excellence-2023.svg'">
            </div>
            <div v-html="format(offer.texte_step3)"></div>
          </div>
          <div v-for="(key, index) in keys" :key="index" class="offer-guaranty">
            <div @click="activeKey === index ? activeKey = false : activeKey = index" class="guaranty-title" data-icon_after="down" :class="{'guaranty--active': activeKey === index}">
              {{ offer[key].titre }}
              <div class="stars"><span class="star" v-for="n in 4" :class="{'star-inactive': n > offer[key].nb_etoiles}" :style="{'color': offer.couleur_2 }" data-icon="star"></span></div>
            </div>
            <transition name="fade-in">
              <div v-if="activeKey === index">

	              <p v-if="offer[key].motif">
		              <b>{{ __('step_3.pattern') }}</b><br/>
                  <span class="light">{{ offer[key].motif }}</span>
	              </p>

                <div data-row>
                  <div v-if="offer[key].depense" data-col="1/2">
                    <div class="guaranty-block">
                      <span class="light">{{ __('step_3.expense') }}</span>
                      <b>{{ offer[key].depense }}</b>
                    </div>
                  </div>
                  <div v-if="offer[key].reste_a_charge" data-col="1/2">
                    <div  class="guaranty-block guaranty-block--secondary">
                      <span class="light">{{ __('step_3.left') }}</span>
                      <b :style="{'color': offer.couleur_3 }">{{ offer[key].reste_a_charge }}</b>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <div v-if="offer.brochure && $store.getters.profil().main_user.status !== 'idf'" class="offer-brochure">
            <a target="_blank" :href="api_endpoint+'/pdf/'+selected_year+'/'+offer.brochure">{{ __("step_3.detail_guaranty") }}</a>
          </div>
          <div class="input-container" :class="{'loading':loading}">
            <a @click="submit()" class="button">{{ __('step_3.cta') }}</a>
          </div>
        </div>
      </div>

      <div class="others">

        <div class="others-heading" v-if="$store.getters.profil().main_user.status !== 'idf'">{{ __('step_3.choose') }}</div>

        <div class="sub-element-container" v-if="$store.getters.profil().main_user.status === 'idf'">
              <div class="contenu">
                <p v-html="__('step_3.idf_not_corresponding')"></p>
                <span>{{ __('step_3.idf_change_status') }}</span>
              </div>
        </div>


        <div class="others-grid">
          <div class="other" v-for="offer in offers" v-if="mustShow(offer)">
            <div class="other-title" :style="{'background-color': offer.couleur }">
              <img :src="'img/offers/'+selected_year+'/'+offer.logo">
              <h3 class="offer-title" :style="{'color': offer.couleur_3 }">
                <span class="light">{{ __('step_3.formula') }}</span>
                <b v-html="format(offer.titre)"></b>
              </h3>
            </div>
            <div class="other-price" :style="{'color': offer.couleur_4 }">
              <b v-if="all_prices">{{ roundPrice(all_prices[offer.offre].plan, false) }}<small>€</small></b>
              <b v-else class="loader"></b>
              <span>{{ __('step_3.monthly') }}</span>
            </div>
            <a @click="select(offer.offre)" class="button button--light" :class="{'button--disabled':!all_prices}">{{ __('step_3.more') }}</a>
          </div>
        </div>
      </div>

      <transition name="fade">
        <div class="c-popin" v-if="showPopinIdf">
          <div class="c-popin-content code_content c-popin-content-idf">
            <h6 class="c-popin-title errorcode"> {{ __('step_3.idf_popin_title') }}</h6>
            <p class="email code_message">{{ __('step_3.idf_popin_content') }}</p>
            <div class="button-container">
              <button class="button" @click="closepopin()">{{ __('transverse.close') }}</button>
            </div>
          </div>
        </div>
      </transition>

      <transition name="fade">
        <div class="c-popin" v-if="showPopinIdfGarantie">
          <div class="c-popin-content code_content c-popin-content-garantie">
            <h6 class="c-popin-title errorcode" v-html="__('step_3.idf_popin_content_garantie')"></h6>
            <div class="button-container">
              <button class="button" @click="closepopinAndDownload()">{{ __('transverse.close') }}</button>
            </div>
          </div>
        </div>
      </transition>

    </div>

    <div class="submit-container submit-container--padding">
      <a class="link link--previous" @click="previous()"><span>{{ __('step_2.previous') }}</span></a>
    </div>

  </div>
</template>

<script>

  export default {
    name: 'step3',
    data(){
      return {
        api_endpoint: process.env.VUE_APP_API_ENDPOINT,
        loading: false,
        keys: ['soins_medicaux','optique_et_dentaire','hospitalisation','prevention'],
        activeKey: false,
        all_prices: false,
        showPopinIdf: false,
        showPopinIdfGarantie: false
      }
    },
    methods:{
      previous(){
        if(this.$store.getters.profil().main_user.status === 'idf'){
            this.$store.commit('step', 1);
        }else {
          this.$store.commit('step', 2);
        }
      },
      closepopin() {
        this.showPopinIdf = false
      },
      closepopinAndDownload(){
        this.showPopinIdfGarantie = false
        window.open(this.api_endpoint+'/pdf/'+this.selected_year+'/'+this.offer.brochure, '_blank', 'noreferrer');
      },
      popinIdf(){
        let self = this;
        setTimeout(function () {
          var anchor_start = document.querySelector(".v-step-idf");
          var popin_content = document.querySelector(".c-popin-content-idf");
          self.scrollToElement(".v-step-idf");
        }, 100)

        this.showPopinIdf = true
      },
      popinGarantieIdf(){
        let self = this;
        setTimeout(function () {
          var anchor_start = document.querySelector(".v-step-idf");
          var popin_content = document.querySelector(".c-popin-content-garantie");
          self.scrollToElement(".v-step-idf");
        }, 100)

        this.showPopinIdfGarantie = true
      },
      submit(){

        if( this.loading )
          return;

        let self = this;
        this.loading = true;

        this.$store.dispatch('updateUser').then(function () {

          dataLayer.push({
            'event': 'funnel_step3',
            'customerType': self.$store.getters.profil().main_user.status,
          });

          self.loading = false;
          self.$store.commit('step', 4);

        }).catch(function (e) {

          self.loading = false;
          self.$store.commit('error', e);
        });
      },
      mustShow(offer){

        offer = parseInt(offer.offre);
        let selected_offer = parseInt(this.offer.offre);
        let offers_count = Object.keys(this.offers).length;
        let available_offers = [this.selected==1?this.selected+2:this.selected-1, this.selected, this.selected==offers_count?this.selected-2:this.selected+1];

        return available_offers.indexOf(offer)>=0 && offer != selected_offer;
      },
      select(id){
        this.$store.commit('offer', id);
        this.$store.commit('prices', this.all_prices[id]);
      },
      format(string){
        return string ? string.replace(' 1', '&nbsp;1').replace(' 2', '&nbsp;2').replace(' 3', '&nbsp;3').replace(' 4', '&nbsp;4') : ''
      }
    },
    computed: {
      needs(){
        return this.$store.getters.needs();
      },
      offer(){
        return this.getOffer(this.$store.getters.status(), this.$store.getters.offer(), this.$store.getters.selected_year());
      },
      offers(){
        return this.getOffers(this.$store.getters.status(), this.$store.getters.selected_year());
      },
	    selected_year(){
        return this.$store.getters.selected_year();
      },
      selected(){
        return this.$store.getters.selected();
      },
      status(){
        return this.$store.getters.status();
      },
      prices(){
        return this.$store.getters.prices();
      }
    },
    mounted() {

      let self = this;

      if(this.status=='idf')
        this.popinIdf();

      this.$store.dispatch('getQuotes').then(function (response) {

        self.all_prices = response.prices;

      }).catch(function (e) {

        self.$store.commit('error', e);
      });
    }
  }
</script>

<style lang="scss">
  @import '../environment';
  .v-step-idf{
    .guaranty-block {
      b {
        color: black !important;
      }
    }
    .offer-summary {
      .offer-title {
        margin-left: 1.5rem !important; margin-right: 1.5rem !important;
      }
    }
    .pdf{
      height: 73px !important; padding: 15px 0 !important; bottom: 0 !important;
      @media #{$from-large}{ height: 92px !important; padding: 24px 0 !important; }
      a{ display: block; margin-bottom: 10px; cursor: pointer; }
    }
    .errorcode b{ color: #4E9870; font-size: 2rem; }
  }

  .v-step{
    text-align: left;
    .offer-title{
      color: white;
      & > *{ display: block}
      span{ font-weight: 300; font-size: 1.8rem }
      b{ font-size: 3rem; line-height: 1 }
    }

    .sub-element-container {
      display: flex; 
      margin: 3em 2em 0em 2em
    }
    .sub-element-container > .contenu {
    padding: 3.5rem 0;
    width: 40%;
    margin-left: -1px;
    margin-top: -1px;
    margin-bottom: -1px;
    }

   .sub-element-container > .contenu p  {
     font-weight: bold;
     font-size: 1.8rem;
     color: #3e0b7a;
     line-height: 23px;
    }

    .sub-element-container > .contenu span  {
     font-size: 1.4rem;
     line-height: 23px;
    }

   .sub-element-container > .btn-element{
      flex: 1;
      padding: 5.5rem 3.5rem 1rem;
      position: absolute;
      right: 0;
    }

    .sub-element-container > .btn-element > .input-container{
      display: inline-block;
     font-weight: bold;
     font-size: 1.8rem;
     cursor: pointer;
     padding: 1.5rem 3rem;
    background: white;
    color: #3e0b7a;
    border: solid 2px #3e0b7a;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    width: 322px;
    height: 52px;
    
    }

    .selected-offer{
      border: 1px solid #E6E6E6; margin-top: 2.5rem;
      @media #{$from-small}{ margin: 3rem 2rem; display: flex }

      .offer-summary{
        padding: 2rem; color: white; position: relative;
        @media #{$from-small}{ padding: 3.5rem 0 7rem; width: 40%; margin-left: -1px; margin-top: -1px; margin-bottom: -1px }
        .offer-title{ text-align: center; margin-left: 3.5rem; margin-right: 3.5rem; margin-top: 0.5rem;}
        .logo{
          width: 50%; margin: 2rem auto; margin-bottom: 4rem;
          .rac0{ margin-left: auto; width: 4rem; margin-top: 0.5rem }
        }
        .price{ background: $c-secondary; display: flex; justify-content: center; align-items: center; flex-direction: column;
          padding: 2rem; text-transform: uppercase;
          b{
            font-size: 5.5rem; text-shadow: 2px 1px 6px rgba(0,0,0,0.2);
            small{ color: white; font-size: 2.5rem; margin-left: 1rem }
          }
          span{ font-weight: 900; font-size: 1.6rem }
        }
        .pdf{
          position: absolute; bottom: 2.5rem; width: 100%; text-align: center; text-decoration: underline;
          @media #{$to-tablet}{ position:inherit;display: block;bottom: inherit;padding-top: 10px; }
        }
      }
      .offer-guaranties{
        flex: 1; padding: 3.5rem 3.5rem 6rem; position: relative;
        @media #{$to-tablet}{
          padding: 2rem 2rem 4rem;
        }
        @media #{$from-small}{
          .pdf{ display:none }
        }
        .guaranties-intro{
          font-weight: bold; font-size: 1.3rem;
          &-image{
            width: 30%; margin: auto;
            img{ width: 100%; }
            @media #{$to-phone}{ & + *{ margin-top: 2rem} }
          }
          @media #{$only-tablet}{ width: 50%; margin: auto; }
          @media #{$to-phone}{ width: 70%; margin: auto; }
          @media #{$from-tablet}{ display: flex; align-items: center;
            &-image{
              & + *{ flex: 1; margin-left: 2rem}
            }
          }
          & + *{ margin-top: 2rem }
        }
        .guaranty-title{
          font-weight: 300; font-size: 1.8rem; padding: 0.5rem 2.8rem 0 0.5rem; cursor: pointer; position: relative;
          @media #{$only-small}{ font-size: 1.6rem; }
          &:after{ position: absolute; width: 2.8rem; right: 0; bottom: 0; top: 0.5rem; display: flex; align-items: center;
            justify-content: center; transition: allow(transform);
            @media #{$to-phone}{ top: 3rem }
            }
          & + *{ margin-top: 2rem  }
          &.guaranty--active:after{ transform: rotate(180deg)}
        }
        .offer-guaranty{
          padding: 1rem 0; border-bottom: solid $c-background 1px;
          [data-col]{ display: flex }
        }
        .stars{
          float: right; border-right: solid 1px $c-background; padding-right: 0.7rem;
          @media #{$to-phone}{ text-align: right; float: none }
        }
        .star{
          display: inline-block; font-size: 1.8rem;
          & + .star{ margin-left: 8px }
          &-inactive{ color: $c-background!important; }
        }
        .input-container{
          position: absolute; right: 0; bottom: -2.5rem;
          @media #{$to-tablet}{ white-space: nowrap; display: block; right: auto; left: 50%; transform: translateX(-50%) }
         }
        .offer-brochure{
          margin-top: 15px;
          a{ text-decoration: underline }
        }


        .guaranty-block{
          display: flex; flex-direction: column; background: $c-background; align-items: center; justify-content: center; padding: 1rem 0; flex: 1; margin: 1.5rem 0 2rem;
          b{ font-size: 2.4rem }
          &--secondary b{ color: $c-secondary }
        }
      }
    }

    .others{
      //padding-top: 6rem;
      @media #{$to-tablet}{ background: $c-background; margin-left: -1.5rem; margin-right: -1.5rem; margin-bottom: -2rem }
        &-grid{
        display: flex; justify-content: space-between; margin: 2rem;
        @media #{$to-tablet}{ flex-direction: column }
        & > *{
          width: 48%; border: solid 1px #E6E6E6;
          @media #{$to-tablet}{
            width: 100%;
            & + *{ margin-top: 3rem }
          }
          }
      }
      .others-heading{ text-align: center; font-weight: 900; font-size: 2.4rem; margin-top: 50px; padding-top: 30px; }
      .other{
        text-align: center; background: white;
        .button{ margin-bottom: -2.7rem; border-radius: 0 }
      }
      .other-title{
        padding: 2.5rem; display: flex; text-align: left; margin-left: -1px; margin-top: -1px; margin-bottom: -1px; align-items: center;
        img{ width: 33%;
          & + h3{ margin-left: 2.5rem }
        }
        .offer-title{ flex: 1; text-align: left }
      }
      .other-price{ color: $c-secondary; display: flex; justify-content: center; align-items: center; flex-direction: column;
        padding: 2rem; text-transform: uppercase;
        b{
          font-size: 4.5rem;
          small{ font-size: 1.5rem; margin-left: 0.5rem }
        }
        .loader{
          &:after{
            content: ""; width: 36px; height: 36px; border: 5px solid $c-secondary; border-left-color: transparent;
            border-radius: 50%; animation: rotation 1s linear infinite; display: inline-block;
            pointer-events: none; transition: allow(opacity);
          }
        }
        span{ font-weight: 900; font-size: 1.6rem }
      }
    }
    .submit-container--padding{
      padding: 1rem 0; text-align: right;
      @media #{$to-tablet}{ margin-top: -1.5rem; padding-top: 3rem }
    }

    .c-popin{

    }
    
  }

  // X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {  }

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
  .v-step .sub-element-container {
    flex-direction: column;
}

.v-step .sub-element-container > .btn-element {
    padding: 0px;
    position: initial;

}
.v-step .sub-element-container > .contenu {
    width: 100%; 
    padding: 3.5rem 0 3.5rem 0rem;
}
}


// Large devices (desktops, 992px and up)
@media (min-width: 992px) { }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {  }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {  }
</style>
