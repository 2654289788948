<template>
  <div>
    <header>
        <div class="header-logo">
          <img src="../public/img/lmf-logo.png" alt="logo LMF">
        </div>
        <div class="header-cta">
          <button>
            <a href="https://www.mutuelle-familiale.fr/"> Revenir au site</a>
          </button>
        </div>
    </header>
    <main id="app">
      <div class="background">
        <div class="background-color"></div>
        <div class="background-container">
          <img src="/img/Visuel.png" alt=""/>
        </div>
      </div>

      <heading/>
      <div class="container container--background">
          <div class="main_content" data-row>
          <div data-col="7/10">
            <div class="steps">
              <steps :currentStatus= "currentValueStatus" />
              <step1 v-if="current_step===1"/>
              <step2 v-if="current_step===2 "/>
              <step3 v-if="current_step===3"/>
              <step4 v-if="current_step===4"/>
            </div>
          </div>
          <div data-col="3/10">
            <cart/>
          </div>
        </div>
      </div>
      <popin-error></popin-error>
    </main>
    <footer>
      <div class="partners-infos">
        <div class="all-lmf">
          <img src="../public/img/lmf-logo.png" alt="logo LMF" width="260">
          <div class="platforms">
            <a> <img src="../public/img/facebook.png" alt=""> </a>
            <a> <img src="../public/img/x.png" alt=""></a>
            <a> <img src="../public/img/instagram.png" alt=""> </a>
            <a> <img src="../public/img/linkedin.png" alt=""> </a>
            <a> <img src="../public/img/youtube.png" alt=""> </a>
          </div>
        </div>
        <div class="infos">
          <!--            Add variable to custom field of span      -->
          <span>La mutuelle Familiale, est une complémentaire santé solidaire et équitable, qui prévient les risques par des actions de prévention santé et de prévoyance.</span>
        </div>
        <div class="partners">
          <div>
            <img src="../public/img/mutualite-francaise-logo.png" alt="logo mutualité française">
          </div>
          <div>
            <img src="../public/img/umanens-logo.png" alt="logo Umanens">
          </div>
        </div>
      </div>
      <div class="legal">
        <div>
          <span>Copyright © 2023 LA MUTUELLE FAMILIALE - Tous droits réservés</span>
        </div>
        <!--      Add variable to custom field of legal links     -->
        <div class="legal-terms">
          <a href="">Cookies</a><span>●</span>
          <a href="">Mentions légales</a><span>●</span>
          <a href="">Politique de confidentialité</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
  // @ is an alias to /src
  import Cart from '@/components/Cart.vue'
  import Steps from '@/components/Steps.vue'
  import Heading from '@/components/Heading.vue'
  import PopinError from '@/components/PopinError.vue'

  import Step1 from '@/views/Step1.vue'
  import Step2 from '@/views/Step2.vue'
  import Step3 from '@/views/Step3.vue'
  import Step4 from '@/views/Step4.vue'

  export default {
    name: 'app',
    components: {
      Cart,
      Steps,
      Heading,
      Step1,
      Step2,
      Step3,
      Step4,
      PopinError
    },
     data () {
    return {
      currentValueStatus: null
    }
     },
    computed: {
      current_step(){
        return this.$store.getters.step();
      }
    },

      methods: {
        currentStatus(value) {
          console.log('[App.vue] currentStatus ')
          this.currentValueStatus = value
           this.$emit('changestatus_', value)
    },

      }
  }
</script>


<style lang="scss">
  @import 'environment';

  //@import '../node_modules/vue-form-generator/dist/vfg.css';

  @import '../node_modules/@metabolism/framework/scss/reset';
  @import '../node_modules/@metabolism/framework/scss/data-attribute/grid';

  @import '../node_modules/@metabolism/framework/vuejs/reset';
  @import '../node_modules/@metabolism/framework/scss/misc/fonts';

  html{ font-size: 10px }
  .popin-cart-open,.popin-open{ height: 100%; overflow: hidden }
  header {
    display: flex;
    padding: 0 $x-large;
    align-items: center;

    @media (min-width: 425px) and (max-width: 767px) {padding: 0; justify-content: center; margin: $small;}
    @media #{$include-phone} {margin: $small; padding: 0;}

    .header-logo {
      img {
        max-width: 280px;

        @media #{$to-tablet}{max-width: 260px;}
        @media #{$to-phone}{max-width: 175px;}
      }
      @media #{$to-tablet}{padding: $small;}
    }

    .header-cta {
      margin-left: auto;
      padding: 2rem 10rem;

      @media #{$include-phone}{padding: 0;margin-left: 0;}
      @media (min-width: 425px) and (max-width: 767px) {padding: $small;}

      > button {
        background-color: white;
        border-radius: $border-radius ;
        border: 1px solid $c-secondary;
        color: $c-secondary;
        padding: 11px 30px;
        cursor: pointer;

        @media #{$to-tablet}{padding: calc(2 * $small) calc(3 * $small); font-size: $lh-l}
        @media #{$to-phone}{padding: $small calc(2 * $small); font-size: $lh-l}
        @media #{$include-phone-m}{padding: $small;}
        @media #{$include-phone-s}{font-size: $lh-s}

        &:hover {
          background-color: $c-secondary;
          color: white;
          transition: $transition-duration;
        }
      }
    }
  }
  footer {
    max-width: $container-w; margin-left: auto; margin-right: auto;
    padding: $medium 0;
    font-family: Avenir , Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $c-main; font-size: 1.6rem; line-height: 1.3;
    @media #{$to-phone}{overflow:hidden}

    .partners-infos {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: $medium 0;
      margin: 0 $small;
      @media #{$to-phone}{flex-direction: column; align-items: center; margin: 0 $small; padding-top: 0; padding-bottom: $small}
      @media #{$only-tablet}{align-items: center;}

    }

    .all-lmf{
      @media #{$only-tablet}{max-width: 200px}
    }

    .platforms {
      display: flex;
      justify-content: space-between;
      margin-top: $small;
      @media #{$to-phone}{margin-bottom: $medium }

      a {
        display: flex;
        justify-content: center;
        background-color: $c-secondary;
        padding: $small;
        border-radius: $small;
        height: 37px;
        width: 37px;
        cursor: pointer;
      }
    }

    .infos {
      text-align: left;
      max-width: 600px;
      @media #{$to-phone}{margin-bottom: $small; text-align: center}
      @media #{$only-tablet}{ text-align: center;max-width: 400px;}
      @media #{$only-small}{margin: 0 $small}
    }

    .partners {
      width: auto;
      display: flex;
      align-items: flex-end;
      @media #{$to-phone} {margin-bottom: $small}

        div {
        margin: 0 $small;
      }
    }
    .legal {
      padding: $medium;
      display: flex;
      justify-content: space-around;
      border-top: 1px solid $c-primary;
      @media #{$to-phone}{flex-direction: column; text-align: center}
    }

    .legal-terms {
      margin: 0 1rem;
      @media #{$to-phone}{margin-top: $small}

      span {
        padding: $small;
      }
    }
  }
  #app {
    position: relative;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $c-main; font-size: 1.6rem; line-height: 1.3;
    @media #{$from-small}{overflow:hidden}

    @media #{$to-small}{
      input[type="color"],
      input[type="date"],
      input[type="datetime"],
      input[type="datetime-local"],
      input[type="email"],
      input[type="month"],
      input[type="number"],
      input[type="password"],
      input[type="search"],
      input[type="tel"],
      input[type="text"],
      input[type="time"],
      input[type="url"],
      input[type="week"],
      select:focus,
      textarea {
        font-size: 16px;
      }
    }

    .container{ max-width: $container-w; margin-left: auto; margin-right: auto;
      &--background{ background: $c-background }
    }
    .main_content{
      z-index: 3; position: relative;
      @media #{$from-small}{ padding-left: 10%; padding-right: 10%;  }
      @media #{$only-small}{ padding-left: 2rem; padding-right: 4rem;  }
    }
    .background{
      position: overlay(); pointer-events: none; padding-top: 0.5rem; height: 458px;
      .background-color{
        position: overlay(); background: $c-secondary; background-size:100% 430px; z-index: 0;
        @media #{$to-tablet}{ background-size: 100% 250px; background-position: left top }
      }
      &-container{
        max-width: 1476px; position: absolute; z-index: 1; bottom:0;
        @media #{$to-tablet}{
          position: absolute; max-width: 50%; left: 1rem; top: 9rem; z-index: 2;
        }
        @media screen and (max-width: 1190px){
          display: none;
        }
      }
    }
  }
  .light{ font-weight: 300 }

  .steps{
    padding-top: 3.5rem; margin-bottom: 10rem;
    @media #{$to-tablet}{  padding-top: 1rem; margin-bottom: 2rem }
    @media #{$from-small}{  background: white }

	  .alert{
		  display: flex; align-items: center; color: #FF6082; font-weight: bold; background: white; font-family: AvenirNextCondensed; font-size: 2.6rem; line-height: 3rem; text-align: center; text-transform: uppercase;
		  @media #{$to-tablet}{ padding: 2rem 1.5rem 0 }
		  @media #{$from-tablet}{ font-size: 2.2rem; }
		  @media #{$from-small}{
			  margin: 0 5rem 4rem;
		  }
		  & > * + *{ margin-left: 2rem }
	  }
  }

  input{ border: 1px solid; font-size: 1.4rem; box-shadow: none }
  input[type=radio]{ border-radius: 50% }
  input[type="text"],input[type="date"],input[type="email"],input[type="tel"], select{
    padding: 1rem 1.5rem; border-color: $c-primary;
    border-radius: $border-radius; width: 100%; height: 4.2rem; background: white; font-family: inherit;
    .error &{ border-color: $c-error }
  }
  input[type="radio"],input[type="checkbox"]{width: 14px; height: 14px;  margin: 0 1rem 0 0;  background: white; cursor: pointer; box-shadow: none }
  input[type="radio"]{ border: solid 2px white; z-index: 1 }
  input[type="radio"]:checked,input[type="checkbox"]:checked{ border-color: white; background:$c-primary }
  input[type="checkbox"]{  }
  .input-checkbox,.field-customcheckbox .field-wrap{
    position: relative;

    input[type="checkbox"]{ display: block; margin: 0; border: solid 1px $c-primary; cursor: pointer;  border-radius: 2px;
      &:checked ~ .checkmark{ opacity: 1 }
    }

    .checkmark{
      opacity: 0; transition: allow(opacity);
      &:before{ position: overlay(); pointer-events: none; display: flex; align-items: center; justify-content: center;
        color: white; font-size: 0.9rem }
    }
  }
  .field-customcheckbox{
    display: flex; flex-direction: row-reverse; align-items: flex-start;
    &.error{
      label{ flex: 1 }
      input[type="checkbox"]{ border-color: $c-error }
    }
    label{
      margin-left: 1rem;

      @media #{$to-tablet}{ margin-right: auto }
    }
  }

  .radio-list label{
    position:relative;
    &:before{ content: ''; position: absolute; top: 0; left:-2px; width: 18px; height: 18px; border-radius: 50%; background: $c-primary; z-index: 0 }
  }

  .button{
    display: inline-block; font-weight: bold; font-size: 1.8rem; cursor: pointer; transition: allow(background color); border-radius: 5px;
    text-decoration: none; padding: 1.5rem 4rem; background: $c-secondary; color: white; border: solid 2px $c-secondary; font-family: Avenir, Helvetica, Arial, sans-serif;
    @media #{$to-tablet}{ padding: 1.4rem 3rem; font-size: 1.6rem; border-width: 2px }
    @media #{$from-small}{ &:hover{ background: white; color: $c-secondary } }

    &--light{
      color: $c-secondary; background: white;
      @media #{$from-small}{ &:hover{ background: $c-secondary; color: white } }
    }
    &--disabled{
      pointer-events: none; color: grey; border-color: grey;
    }
    &--add{
      border-radius: 8px;
      &:before{ font-size: 2.5rem; margin-right: 1rem }
    }
    &--loading{
      background: white; color: white!important; cursor: wait
    }
  }

  .input-container{
    display: inline-block; position: relative;
    &:after{
      content: ""; position: absolute; width: 36px; height: 36px; border: 5px solid $c-secondary; border-left-color: transparent;
      border-radius: 50%; top: calc(50% - 18px); left: calc(50% - 18px); animation: rotation 1s linear infinite; opacity: 0;
      pointer-events: none; transition: allow(opacity);
    }
    &.loading{
      &:after{ opacity: 1 }
      .button{ background: white; color: white!important; cursor: default }
    }
  }

  .link{
    color: $c-secondary; font-weight: bold; font-size: 1.6rem; text-decoration: underline;
    display: inline-block; cursor: pointer;

    &--previous{
      text-decoration:none; @include icon('previous');
      span{ text-decoration: underline }
    }
  }

  .slide-left-enter-active {
    animation: slide-left 0.3s $ease-in-out-cubic; animation-fill-mode: both;
  }
  .slide-left-leave-active {
    animation: unslide-left 0.3s $ease-in-out-cubic; animation-fill-mode: both;
  }

  .fade-enter-active, .fade-in-enter-active {
    animation: fade-in 0.3s $ease-in-out-cubic; animation-fill-mode: both;
  }
  .fade-leave-active {
    animation: fade-out 0.3s $ease-in-out-cubic; animation-fill-mode: both;
  }

  @keyframes slide-left { 0%{ opacity: 0; transform: translateX(100%) } 100%{ opacity: 1; transform: none }}
  @keyframes unslide-left { 100%{ opacity: 0; transform: translateX(100%) } 0%{ opacity: 1; transform: none }}

  @keyframes fade-in { 0%{ opacity: 0 } 100%{ opacity: 1 } }
  @keyframes fade-out { 0%{ opacity: 1 } 100%{ opacity: 0 } }

  @keyframes rotation{
    0%{transform:rotate(0)}
    to{transform:rotate(359deg)}
  }

  @import "../node_modules/@metabolism/framework/scss/class/unsupported-browsers.scss";

</style>
