<template>
  <nav class="c-steps" :class="'c-steps--'+current_step">

    <a @click="goTo(1)" class="step" :class="{'step--active':current_step===1}">
      <span class="c-steps-number">
        <span>1</span>
      </span>
      <small>{{ __('steps.profil') }}</small>
    </a>

    <a @click="goTo(2)" class="step" :class="{'step--inactive':(currentStatus === 'idf'),'step--disabled':current_step<2, 'step--active':current_step===2}">
     <span class="c-steps-number">
        <span>2</span>
      </span>
      <small>{{ __('steps.needs') }}</small>
    </a>

    <a @click="goTo(3)" class="step" :class="{'step--disabled':current_step<3, 'step--active':current_step===3}">
      <span class="c-steps-number">
        <span v-if="currentStatus === 'idf'">2</span>
        <span v-if="currentStatus !='idf'">3</span>
      </span>
      <small>{{ __('steps.proposal') }}</small>
    </a>

    <a @click="goTo(4)" class="step" :class="{'step--disabled':current_step<4, 'step--active':current_step===4}">
      <span class="c-steps-number">
        <span>4</span>
      </span>
      <small>{{ __('steps.options') }}</small>
    </a>
  </nav>
</template>

<script>

  export default {
    name: 'steps',
    computed: {
      current_step(){
        return this.$store.getters.step();
      }
    },
     props: {
     currentStatus: String
    },
    methods:{
      goTo(step){

        if(step==2 && this.$store.getters.profil().main_user.status === 'idf') {
          return ;
        }

        this.$store.commit('step', step);
      },
    
    }
  }
</script>

<style lang="scss">
  @import '../environment';
  .c-steps{
    display: flex; justify-content: space-between; position: relative; z-index: 1; margin: 0 1rem;
    @media #{$to-tablet}{
      background: white; padding-top: 2rem; padding-bottom: 2rem; margin: 0; padding-left: 1.5rem; padding-right: 1.5rem;
      &:before{ content: ''; position: absolute; left: -1rem; right: -1rem; bottom: 0; background: white; top: 1rem; z-index: -1 }
    }
    @media #{$from-small}{
      margin: 0 5rem 3.5rem;
    }
    &:after, &:before{
      content: ""; position: absolute; top: 3rem; left: 3rem; right: 3rem; height: 2px; background: $c-subtle; z-index: -1;
      .ie &{ left: 7rem; right: 7rem }
      @media #{$to-tablet}{ top: 4rem }
    }
    &:before{ z-index: -2 }
    &:after{ background: $c-primary; transform: scaleX(0); transition: allow(transform); transform-origin: left }
    &--2:after{ transform: scaleX(0.33) }
    &--3:after{ transform: scaleX(0.66) }
    &--4:after{ transform: scaleX(1) }
    .step{
      font-weight: 700;display: flex; flex-direction: column; justify-content: space-between; text-transform: uppercase; cursor: pointer;
      .c-steps-number{
        font-family: AvenirNextCondensed; margin: 0 auto 1rem; border-radius: 50%; border: 2px solid $c-primary; color: $c-primary;
        background: white; cursor: pointer; width: 4rem; height: 4rem; display: flex; align-items: center;
        justify-content: center; font-size: 0;
        @include icon ('tick');
        &:before{ font-size: 2rem }
        .macos & span,.ios & span{ margin-top: 4px }
        @media #{$from-small}{
          width: 6rem; height: 6rem;
          &:before{ font-size: 3rem }
        }

      }
      @media #{$to-tablet}{ small{ font-size: 0.8rem } }

      &--active{
        pointer-events: none;
        .c-steps-number{
          background: $c-primary; color: white; border-color: $c-primary; font-size: 2rem; pointer-events: none; padding-top: 0.2em;
          @media #{$from-small}{ font-size: 3.5rem; }
          &:before{ display: none }
        }
      }
      &--disabled{
        font-weight: 300; pointer-events: none;
        .c-steps-number{
          font-weight: 900; border-color: $c-subtle; color: $c-subtle; font-size: 2rem; padding-top: 0.2em;
          @media #{$from-small}{ width: 4rem; height: 4rem; margin-top:1rem; }
          &:before{ display: none }
        }
      }
    }
  }
</style>
