export const Dictionary = {
	data(){
		return {
			text_sheet: 'texte'
		}
	},
	methods: {
		__(key) {
			let data = this.dico;
			let keys = [this.text_sheet];
			key = key.replace(/ > /g, '.').replace(/ /g, '_').toLowerCase().split('.');

			keys = keys.concat(key);

			for(let i = 0; i < keys.length; i++) {

				let entry = keys[i];

				if( data && entry in data )
					data = data[entry];
				else
					return '['+key.join('.')+']';
			}

			return data.replace(/\n/g, '<br/>');
		},
		selectOffer(status, val1, val2, val3, val4){

			let key = val1+'_'+val2+'_'+val3+'_'+val4;

			if( 'deviseur_'+status in this.dico && key in this.dico['deviseur_'+status].matrix )
				return this.dico['deviseur_'+status].matrix[key];
			else
				return false;
		},
		isInIframe(){
			try {
				return window.self !== window.top;
			} catch (e) {
				return true;
			}
		},
		getOffer(status, offer, year){

			if( this.dico && 'offres_'+year+'_'+status in this.dico && status+offer in this.dico['offres_'+year+'_'+status] )
				return this.dico['offres_'+year+'_'+status][status+offer];
			else
				return false;
		},
		getOffers(status, year){

			if( this.dico && 'offres_'+year+'_'+status in this.dico )
				return this.dico['offres_'+year+'_'+status];
			else
				return {};
		},
		roundPrice(value, euro){

			if( typeof euro == 'undefined')
				euro = true;

			if( typeof value == 'undefined')
				return '';
			
			return (value.toFixed(2)+(euro?' €':'')).replace('.',',');
		},
		scrollToElement(refName) {
			var element = document.querySelector(refName);
			var top = element.offsetTop;
			//console.log(top);
			window.scrollTo({
				top: top,
				left: 0,
				behavior: "smooth",
			});
		},
	},
	computed:{
		dico(){
			return this.$store.getters.dico();
		}
	}
};