<template>
	<div class="c-month-picker">
		<a @click="toggle()" class="input" data-icon_after="calendar">{{ formatSelection() }}</a>
		<transition name="fade">
			<div class="c-month-picker-content" v-if="visible">
				<a data-icon="close" @click="visible = false" class="close"></a>
				<div v-for="year in years" v-if="year >= now.getFullYear()">
					<b>{{ year }}</b>
					<div class="c-month-picker-months" data-row>

						<div v-for="(month, index) in months" v-if="isValid(year,index)" data-col="1/4">
							<a @click="select(year, index)" :class="{'is-active': isActive(year, index), 'is-disabled': !isEnabled(year,index)}">{{ month }}</a>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>

	export default {
		name: 'MonthPicker',
		props:['value'],
		data(){
			return{
				visible: false,
				selected:{
					day: false,
					month:false,
					year:false
				},
				now: new Date(),
				years:[2023,2024],
				months:{0:'Janvier', 1:'Février', 2:'Mars', 3:'Avril', 4:'Mai', 5:'Juin', 6:'Juillet', 7:'Août', 8:'Septembre', 9:'Octobre', 10:'Novembre', 11:'Décembre'}
			}
		},
		methods:{
			toggle(){
				this.visible = !this.visible
			},
			formatSelection(){
				let selection = new Date(this.selected.year,this.selected.month,1)
				return selection.toLocaleDateString('fr-FR', { year: 'numeric', month: 'numeric', day: 'numeric' })
			},
			isValid(year, month){
				return (year > this.now.getFullYear() && month < 4) || (year === this.now.getFullYear() && parseInt(month) > this.now.getMonth())
			},
			isActive(year, month){
				return year === this.selected.year && parseInt(month) === this.selected.month
			},
			isEnabled(year,month){
				return year > this.now.getFullYear() || month > this.now.getMonth()
			},
			select(year, month){
				let date = new Date(year, month, 1);
				this.$emit('input', date.getTime());

				this.visible = false;
			}
		},
		watch: {
			value(time){

				let value = new Date(time);
				let now = new Date(Date.now());

				if( value < now ){

					this.selected.day = 1;

					if (now.getMonth() >= 11) {
						this.selected.month = 1;
						this.selected.year = now.getFullYear()+1;
					} else {
						this.selected.month = now.getMonth()+1;
						this.selected.year = now.getFullYear();
					}

					let date = new Date(this.selected.year, this.selected.month, this.selected.day);
					this.$emit('input', date.getTime());
				}
				else{
					this.selected.day = value.getDate();
					this.selected.month = value.getMonth();
					this.selected.year = value.getFullYear();
				}
			}
		},
		mounted() {

			let value = new Date(Date.now());

			this.selected.day = 1;

      if (value.getMonth() >= 11) {
        this.selected.month = 1;
        this.selected.year = value.getFullYear()+1;
      } else {
        this.selected.month = value.getMonth()+1;
        this.selected.year = value.getFullYear();
      }

			let date = new Date(this.selected.year, this.selected.month, this.selected.day);
			this.$emit('input', date.getTime());
		}
	}
</script>

<style lang="scss">
	@import '../environment';
	.c-month-picker{
		position: relative; z-index: 6; margin-left: 1rem; user-select: none;
		.input{
			width: 200px; height: 40px; background: white; border: solid 1px $c-primary; border-radius: $border-radius; display: flex;
			align-items: center; padding: 1rem 2rem; cursor: pointer;
			&:after{ display: flex; position: absolute; top: 0; right: 0; bottom: 0; width: 4rem; background: $c-primary;
				color: white; font-size: 2.2rem; align-items: center; justify-content: center;
				border-top-right-radius: $border-radius; border-bottom-right-radius: $border-radius }
		}
		&-content{
			position: absolute; bottom: 100%; margin-bottom: 0.5rem; background: white; border: 2px solid #E6E6E6; box-shadow: 0 0 10px rgb(0 0 0 / 8%);
			padding: 2rem 2.5rem; text-align: left; width: 526px; ; font-size: 1.6rem;
			& > div + div{ margin-top: 2rem }
			b{ display: block }
			.close {
				display: inline-block; position: absolute; right: 1rem; top: 1rem; cursor: pointer
				//@media #{$from-small}{ display: none }
			}
			@media #{$to-tablet}{
				position: fixed; width: 100vw; height: 100vh; bottom: 0; left: 0; display: flex; justify-content: center; flex-direction: column;
			}
		}
		&-months{
			[data-col]{
				padding: 0 0.5rem;
				@media #{$to-tablet}{ flex-basis: 50% }
			}
			a {
				text-align: center; display: block; padding: 0.5rem; border: solid 1px $c-primary; border-radius: $border-radius; font-weight: bold;
				transition: allow(background color); margin-top: 1rem; cursor: pointer;
				&:hover, &.is-active{ background: $c-primary; color: white }
				&.is-disabled{ background: $c-background; color: $c-subtle; border-color: $c-background; font-weight: 300; pointer-events: none }
			}
		}

	}
</style>
