import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import hash from 'object-hash';

Vue.use(Vuex);

const default_state = function(){
    return {
        selected_year:2021,
        dico:false,
        step:1,
        error: false,
        softError: false,
        subscription_url:false,
        saved_hash:false,
        user:{
            lead:0,
            prices:{},
            offer:1,
            proposal:1,
            profil:false,
            status:'lmf',
            needs:false,
            id:'',
            options:{}
        }
    }
};

export default new Vuex.Store({
    plugins: [
        createPersistedState()
    ],
    state: default_state(),
    mutations: {
        dico(state, payload){
            state.dico = payload;
        },
        selected_year(state, payload){
            state.selected_year = payload;
        },
        step(state, payload){
            window.parent.postMessage({type:'scroll', top:0}, "*");
            window.scrollTo({top:0, behavior: 'smooth' });
            state.step = parseInt(payload);
        },
        profil(state, payload){
            state.user.profil = payload;
        },
        needs(state, payload){
            state.user.needs = payload;
        },
        userId(state, payload){
            state.user.id = payload;
        },
        prices(state, payload){
            state.user.prices = payload;
        },
        offer(state, payload){
            state.user.offer = parseInt(payload);
        },
        status(state, payload){
            state.user.status = payload;
        },
        proposal(state, payload){
            state.user.proposal = parseInt(payload);
        },
        options(state, payload){
            state.user.options = payload;
        },
        subscription_url(state, payload){
            state.subscription_url = payload;
        },
        lead(state){
            state.user.lead++;
            state.saved_hash = hash.MD5(state.user);
        },
        reset(state){
            let initial_state = default_state();
            Object.keys(initial_state).forEach(function(key){
                if( key !== 'dico')
                    state[key] = initial_state[key];
            });
            window.parent.postMessage({type:'scroll', top:0}, "*");
            window.scrollTo({top:0});
            window.location.reload();
        },
        error(state, payload){
            if (payload && payload.includes('existe pas dans le GRA'))
                this.commit('reset');
            else
                state.error = payload;
        },
        softError(state, payload){
            state.softError = payload;
        }
    },
    getters: {
        dico: state => () =>{
            return state.dico;
        },
        selected_year: state => () =>{
            return state.selected_year;
        },
        selected: state => () =>{
            return state.user.needs ? parseInt(state.user.needs.product.replace(/\D/g,'')) : 1;
        },
        step: state => () =>{
            return state.step;
        },
        userId: state => () =>{
            return state.user.id;
        },
        profil: state => () =>{
            return state.user.profil;
        },
        needs: state => () =>{
            return state.user.needs;
        },
        prices: state => () =>{
            return state.user.prices;
        },
        offer: state => () =>{
            return state.user.offer;
        },
        status: state => () =>{
            return state.user.status;
        },
        options: state => () =>{
            return state.user.options;
        },
        subscription_url: state => () =>{
            return state.subscription_url;
        },
        error: state => () =>{
            return state.error;
        },
        softError: state => () =>{
            return state.softError;
        }
    },
    actions: {
        loadConfig (context){

            return new Promise((resolve, reject) => {
                Vue.http.get('config').then(response => {
                    context.commit('dico', response.body);
                    resolve(response.body)
                }).catch(e => { reject(e.body.message) })
            });
        },
        subscribe (context){

            return new Promise((resolve, reject) => {
                Vue.http.post('subscribe', context.state.user).then(response => {
                    resolve(response.body)
                }).catch(e => { reject(e.body.message) })
            });
        },
        getQuote(context, data){

            return new Promise((resolve, reject) => {
                Vue.http.post('quote', {needs:context.state.user.needs, profil:context.state.user.profil, id:context.state.user.id}).then(response => {
                    context.commit('prices', response.body.prices);
                    resolve(response.body);
                }).catch(e => { reject(e.body.message) })
            });
        },
        createUser(context, data){

            context.commit('profil', data);
            const urlParams = new URLSearchParams(window.location.search);
            const utm_source = urlParams.get('utm_source')

            return new Promise((resolve, reject) => {
                Vue.http.post('user', {profil:data, id:context.state.user.id, utm_source:utm_source}).then(response => {
                    context.commit('userId', response.body.user_id);
                    resolve(response.body)
                }).catch(e => { reject(e.body.message) })
            });
        },
        updateUser(context){

            return new Promise((resolve, reject) => {

                let user_hash = hash.MD5(context.state.user);

                if( context.state.saved_hash === user_hash ) {
                    resolve(true);
                }
                else {
                    Vue.http.put('user', context.state.user).then(response => {
                        context.commit('lead');
                        resolve(response.body)
                    }).catch(e => {
                        reject(e.body.message)
                    })
                }
            });

        },
        sendMail(context){

            return new Promise((resolve, reject) => {
                Vue.http.post('mail', context.state.user).then(response => {
                    resolve(response.body)
                }).catch(e => { reject(e.body.message) })
            });
        },
        getQuotes(context){

            return new Promise((resolve, reject) => {
                Vue.http.post('quotes', {needs:context.state.user.needs, profil:context.state.user.profil, id:context.state.user.id}).then(response => {
                    resolve(response.body)
                }).catch(e => { reject(e.body.message) })
            });
        }
    }
})
