<template>
	<div class="c-heading container">
		<div class="inner">
      <h1 v-html="__('header.quote')"></h1>
			<div class="info">
				{{ __('header.need_a_specialist')}} <span v-html="__('header.call_us')"></span>
        <a class="visio" href="https://www.mutuelle-familiale.fr/agence-virtuelle" target="_blank">
          <div class="icon-visio">
            <img src="/img/Vector.svg" alt="icon-arrow">
          </div>
          <span v-html="__('header.visio')"></span>
          <div class="icon-visio">
            <img src="/img/icon-visio.svg" alt="icon-visio">
          </div>
        </a>

			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'heading',
	}
</script>

<style lang="scss">
	@import '../environment';
	.c-heading{
		padding: 3rem 1rem 7.5rem 1rem; z-index: 1;
		@media #{$to-small}{ padding-bottom: 0 }
		@media #{$from-small}{ padding: 4rem 1rem 3.4rem }
		h1{
      font-family: "AvenirNextCondensed"; color: white; padding: 1rem 1rem; font-size: 2.3rem; font-weight: 900; line-height: 1; margin-right: 2rem; text-transform: uppercase;
      i{
        position: relative; display: inline-block; font-style: normal;color: #FEC212;
        &:after{
          position: absolute; left: -2%; top: 100%; margin-top: -6px; content: ""; width: 120%; height: 27px;
          background-size: contain;
        }
      }
      @media #{$from-tablet}{ font-size: 3.5rem }
      @media #{$from-small}{ padding: 2.9rem 0; font-size: 5.6rem;line-height: 5.6rem; width: 55rem; margin-left: 17rem; flex: 1 }
      @media screen and (max-width: 1190px){ width: 69%; margin-left: 1rem }
      @media #{$include-phone}{ width: 100%; margin-left: 0 ; margin-right: 0; text-align: center; padding: $small calc(2 * $small); font-size: 2.8rem;}
      @media #{$include-phone}{padding: $small;}
    }
		.inner{
      display: flex; flex-direction: row; align-items: flex-start; z-index: 1; position: relative; justify-content: flex-end;
			@media #{$from-small}{
				padding-left: 10%;
				padding-right: 10%;
				margin-right: -1rem;
				width: calc(100% + 3rem);
			}
			@media #{$only-small}{ padding-left: 2rem; padding-right: 4rem;  }
      @media #{$to-phone}{
        display: flex; align-items: center;
        flex-direction: column-reverse; padding: $small;
      }
    }
		.info{
      font-family: Avenir, sans-serif;
			background: #FEC212; padding: 0.5rem; display: inline-block; margin-top: -1rem; border-radius: 5px;
			position: relative; line-height: 1.3rem; color: #000; width: 25%; font-weight: 900; font-size: 1.6rem;
      @media screen and (max-width: 1190px){ width: 31% }
      @media #{$to-small}{ padding: 2rem ; margin-bottom: $small}
      @media #{$include-phone}{line-height: 1.6rem; padding: 2rem 3rem; width: 74% }
      @media #{$include-phone-m}{width: 85%}
      @media #{$include-phone-s}{width: 100%}
			@media #{$from-small}{ margin-left: 2%; margin-top: 0; padding: 4rem; line-height: 1.3; width: 30% }
      span i{ font-style: normal; white-space: nowrap }
		}
    .icon-visio {
      @media #{$to-small}{ margin-left: 1rem; }
      img {
       height: 40px; width: 60px;
      }
    }

    .visio{
      background: #fff; border-radius: 5px; color: #000; margin-top: 2rem; padding: 1rem; box-shadow: 6px 6px $c-primary;
      display: flex; flex-flow: row; align-items: center;
      &:hover {
        box-shadow: none; color: $c-primary;
        transition: $transition-duration;
      }
      @media #{$to-small}{ margin-top: 1rem; margin-bottom: 1rem; }
      b{
        width: 40px; display: inline-block; margin-right: 1rem; flex-shrink: 0; position: relative;
        &:after{
          content: ''; position: absolute; right: 0; top: 0; width: 5px; height: 5px; border-radius: 50%; background: #00E516;
          border: 2px solid #fff; box-sizing: content-box;
        }
      }
      span {margin: 0 $small}
    }
	}
</style>
