<template>
	<div>
		<input
				class="form-control"
				type="checkbox"
				v-model="value"
				:disabled="disabled"
				:autocomplete="schema.autocomplete"
				:id="schema.inputName"
				:name="schema.inputName"
				:class="schema.fieldClasses"
				:required="schema.required" />
		<span class="checkmark" data-icon="tick"></span>
	</div>

</template>

<script>
	import { abstractField } from "vue-form-generator";

	export default {
		mixins: [ abstractField ]
	};
</script>

